import React, { useEffect, useState } from 'react'
import _fetch from '../config/api';
import { base_url } from '../config/config'
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';

const DirectIncome = () => {
    const [directIncome, setDirectINcome] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState('')

    useEffect(() => {
        fetchData();
    }, [currentPage, per_page])

    const fetchData = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/direct-staking-income?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {})
            console.log("direct_staking_income", request);
            if (request.status === true) {
            setDirectINcome(request.data)
            setBalance(request?.income_balance)
            setTotalPages(parseInt(request.pagination.total_pages, 10));
            setTotalRecords(parseInt(request.pagination.total_records, 10));
            setLoading(false); 
        } else {
            toast.error(request.message)
            setLoading(false);
        }
        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }
    // console.log("setDirectINcome", directIncome);

    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };
    const formattedBalance = balance?.balance ? parseFloat(balance.balance).toFixed(4) : "0.0000";
    return (
        <>
            <div className="onew p-1">
                <div className="heading  d-flex ">
                    <h4 className='bep_2'>Direct Income</h4>
                    <p className='button-background  '>{formattedBalance}</p>
                </div>
                <div className="market-table">
                    <div className="table-responsive mt-3">
                    {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">Sr No.</th>
                                    <th scope="col">Holding</th>
                                    <th scope="col">Income</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody className='tb_colo'>
                                 {directIncome && directIncome.length > 0 ? (
                                
                                 directIncome.map((item, index) => (
                                    <tr key={index}>
                                        <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                        <td scope='row'>{item.Holding}</td>
                                        <td scope='row'>{item.Income}</td>
                                        <td scope='row'>{item.Description}</td>
                                        <td scope='row'>{item.Date}</td>
                                    </tr>
                                )) ) : (
                                    <tr>
                                        <td scope='row'  colSpan={5} style={{ textAlign: 'center' }}>No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        )}
                               <div className="pagination-custom">
                            <nav aria-label="Pagination">
                                <ul className="pagination justify-content-center">
                                   
                                    {totalPages > 1 && (
                                        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                <span aria-hidden="true">&larr;</span> Previous
                                            </a>
                                        </li>
                                    )}

                                    
                                    <div className="page-numbers">
                                        {renderPageNumbers().map((page, index) => (
                                            <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                {page === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                        {page}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </div>

                                   
                                    {totalPages > 1 && (
                                        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handleNextClick}>
                                                Next <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DirectIncome