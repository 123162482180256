// import React, { useEffect, useState } from 'react'
// import _fetch from '../config/api'
// import { base_url } from '../config/config'
// import { NavLink } from 'react-router-dom'
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";
// const Account = () => {
//     const [name, setName] = useState();
//     const [email, setEmail] = useState();
//     // const [phoneNumber, setPhoneNumber] = useState({ countryCode: '', phone: '' });
//     const [phone, setPhone] = useState({ countryCode: '', phone: '' });

//     useEffect(() => {
//         fetchData();
//     }, [])

//     const fetchData = async () => {
//         try {
//             const request = await _fetch(`${base_url}api/profile`, 'GET', {}, {})
//             console.log("profile", request)

//             if (request.status === true) {
//                 setName(request?.user_data.name)
//                 setEmail(request?.user_data.email)
//                 setPhone(request?.user_data.phone)
//             }

//         } catch (error) {
//             console.log("Error fetching data:", error);
//         }
//     }

//     // console.log("phone", phoneNumber);



//     return (
//         <>
//             <div className="d-flex gap-5">
//                 <div className="col-lg-3 col-md-4 col-sm-3">
//                     <ul className='nav nav-pills  flex-column mt-sm-0'>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <NavLink to='/account' className='nav-link account-setting text-white fs-5' aria-current='page'>
//                                 <span className='ms-3'>Manage Profile</span>
//                             </NavLink>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <NavLink to='/change_password' className='nav-link account-setting fs-5' aria-current='page' >
//                                 <span className='ms-3'>Change Password</span>
//                             </NavLink>
//                         </li>
//                         <li className='nav-item text-white fs-4 my-1 py-2 py-sm-0'>
//                             <NavLink to='/manage_stake' className='nav-link account-setting fs-5' aria-current='page' >
//                                 <span className='ms-3'>Manage Stack Address</span>
//                             </NavLink>
//                         </li>
//                     </ul>
//                 </div>
//                 <div className="col-lg-6 col-md-8 col-sm-8">
//                     <form className="login-form">
//                         <div className="mb-3 register">
//                             <div className="signup-Name mt-3">
//                                 <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
//                                 <input type="text" className="input_style form-control" placeholder="Enter Full Name" value={name} onChange={(e) => setName(e.target.value)} />
//                             </div>
//                             {/* <div className="signup-PhoneNumber mt-3">
//                                 <label htmlFor="exampleFormControlInput1" className="number-style">Phone Number</label>
//                                 {/* <div className="d-flex gap-3">
//                                     <select className="input_style_phone form-control-lg mt-1" >
//                                         <option hidden value="">India</option>
//                                     </select>
//                                     {/* <PhoneInput
//                                                             country={'in'}
//                                                             value={phone.phone}
//                                                             onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
//                                                             inputProps={{
//                                                                 name: 'phone',
//                                                                 required: true,
//                                                                 autoFocus: true
//                                                             }}
//                                                             inputClass={'form-control w-100 mt-2'}
//                                                             containerClass={'cccc'}

//                                                         /> 
//                                     <input type="number" id="phone_number" className="input_style form-control mt-1" placeholder="Enter Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
//                                 </div> 
//                             </div> */}
//                             <div className="signup-PhoneNumber mt-3">
//                                         <label htmlFor="exampleFormControlInput1" className="number-style">Phone Number</label>

//                                         <PhoneInput
//                                             country={'in'}
//                                             value={phone.phone}
//                                             onChange={(value, country) => { setPhone({ countryCode: country.dialCode, phone: value, countryName: country.name }); }}
//                                             inputProps={{
//                                                 name: 'phone',
//                                                 required: true,
//                                                 // autoFocus: true
//                                             }}
//                                             inputClass="input_style"
//                                             containerClass="input_style_phone"

//                                         />


//                                     </div>

//                             <div className="signup-email mt-3">
//                                 <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
//                                 <input type="email" className="input_style form-control" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
//                             </div>

//                             <div className="signup-Name mt-3">
//                                 <label htmlFor="exampleFormControlInput1" className="form-label">Country</label>
//                                 <input type="text" className="input_style form-control" placeholder="India" />
//                             </div>
//                             <div className="mb-3 button mt-4">
//                                 <button type="submit" className="submit-button">Update</button>
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </div>

//         </>

//     )
// }

// export default Account


import React, { useEffect, useState } from 'react'
import _fetch from '../config/api'
import { base_url } from '../config/config'
import { NavLink } from 'react-router-dom'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const Account = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState({ countryCode: '', phone: '' });

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/profile`, 'GET', {}, {})
            console.log("profile", request)

            if (request.status === true) {
                setName(request?.user_data.name)
                setEmail(request?.user_data.email)

                // Assuming phone has the dial code and the number (e.g., '09755543690')
                const fullPhone = request?.user_data.phone;

                // Remove any leading '0' if present in phone number
                const phoneWithoutZero = fullPhone.startsWith('0') ? fullPhone.substring(1) : fullPhone;

                // Set the phone and country code (assuming India, dial code +91)
                setPhone({
                    countryCode: '91',  // You can dynamically extract the country code if provided in the API
                    phone: phoneWithoutZero
                });
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }

    return (
        <>
            <div className="d-flex gap-5 example-2 ">
                <div className="col-lg-3 col-md-4 col-sm-3">
                    <ul className='nav nav-pills  flex-column mt-sm-0'>
                        <li className='nav-item text-white fs-4'>
                            <NavLink to='/account' className='nav-link account-setting text-white fs-5' aria-current='page'>
                                <span className=''>Manage Profile</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4'>
                            <NavLink to='/change_password' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className=''>Change Password</span>
                            </NavLink>
                        </li>
                        <li className='nav-item text-white fs-4'>
                            <NavLink to='/manage_stake' className='nav-link account-setting fs-5' aria-current='page' >
                                <span className=''>Manage Stack Address</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-8">
                    <form className="login-form">
                        <div className="mb-3 register">
                            <div className="signup-Name mt-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
                                <input type="text" className="input_style form-control" placeholder="Enter Full Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>

                            <div className="signup-PhoneNumber mt-3">
                                <label htmlFor="exampleFormControlInput1" className="number-style">Phone Number</label>
                                {/* <PhoneInput
                                    country={'in'}
                                    value={phone.phone}
                                    onChange={(value, country) => setPhone({ countryCode: country.dialCode, phone: value })}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    inputClass="input_style"
                                    containerClass="input_style_phone"
                                /> */}
                                <PhoneInput
                                    country={'in'}
                                    value={phone.phone}
                                    onChange={(value, country) => { setPhone({ countryCode: country.dialCode, phone: value, countryName: country.name }); }}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        // autoFocus: true
                                        Autocomplete: 'off'
                                    }}
                                    inputClass="input_style"
                                    containerClass="input_style_phone"
                                    inputStyle={{
                                        border: '1px solid ',
                                        borderRadius: '10px',
                                        padding: '10px 10px 10px 69px'
                                    }}
                                    buttonStyle={{
                                        borderRadius: '10px 0 0 20px',
                                        border: '1px solid ',
                                    }}
                                />
                            </div>

                            <div className="signup-email mt-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email Address</label>
                                <input type="email" className="input_style form-control" placeholder="Enter Email" value={email} readOnly onChange={(e) => setEmail(e.target.value)} />
                            </div>

                            <div className="signup-Name mt-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Country</label>
                                <input type="text" className="input_style form-control" placeholder="India" />
                            </div>

                            <div className="mb-3 button mt-4">
                                <button type="submit" className="login-button">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Account;
