// import React, { useState, useEffect } from 'react'
// import { base_url } from '../config/config';
// import _fetch from '../config/api'
// import { toast } from 'react-toastify';
// const FundTransfer = () => {

//     const [user_id, setUser_id] = useState("");
//     const [amount, setAmount] = useState("");
//     const [wallet, setWallet] = useState();
//     const [name, setName] = useState("");

//     // console.log('user_id',user_id);

//     const handleToken = async (e) => {

//         e.preventDefault();

//         const token = localStorage.getItem("token");
//         if (!token) {
//             console.error("Token not found");
//             return;
//         }
//         if (!amount || !user_id) {
//             toast.dismiss();
//             toast.error('Please enter the details');
//             // alert('Please enter the details')
//             return;
//         }

//         // console.log("token", token);

//         try {
//             const requestData = {
//                 user_id,
//                 amount,
//             };

//             let response = await fetch(`${base_url}api/token-transfer`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     "Authorization": token,
//                 },
//                 body: JSON.stringify(requestData),
//             })

//             const data = await response.json();
//             console.log("data", data);

//             // setUser_id(""); // Reset title
//             // setAmount(""); // Reset message




//             if (data.status === true) {
//                 toast.dismiss();
//                 toast.success(data.message)
//                 setAmount("");
//                 setUser_id("");
//             } else {
//                 toast.dismiss();
//                 toast.error(data.message)
//             }
//         } catch (error) {
//             console.error('Error with email login:', error);
//         }
//     }

//     const fetchData = async () => {
//         try {

//             const request = await _fetch(`${base_url}api/dashboard`, 'GET', {}, {});
//             // console.log("request", request);
//             if (request.status === true) {
//                 setWallet(request?.wallet_balance)


//             } else {
//                 toast.error(request.message)

//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     }


//     const handlebalance = async () => {
//         const token = localStorage.getItem("token");
//         if (!token) {
//             console.error("Token not found");
//             return;
//         }

//         try {
//             const requestData = {
//                 sponser_id: user_id.trim()
//             };

//             let response = await fetch(`${base_url}api/check_sponser`, {
//                 method: 'POST',
//                 mode: 'cors',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     "Authorization": token,
//                 },
//                 body: JSON.stringify(requestData),
//             })

//             const data = await response.json();
//             console.log("data", data);

//             if (data.status === true) {
//                 setName(data.name);
//             } else {
//                 // toast.error(data.message || "User not found");
//                 setName("");
//             }
//         } catch (error) {
//             console.error('Error fetching sponsor data:', error);
//         }
//     }


//     const handleUserIdChange = (e) => {
//         const userId = e.target.value.trim(); // Trim the value to remove any spaces
//         setUser_id(userId); // Only update the state here
//     };


//     useEffect(() => {
//         fetchData();
//         if (user_id) {
//             handlebalance(); // Call handlebalance when user_id updates
//         }
//     }, [user_id])
//     const formattedBalance = wallet ? parseFloat(wallet).toFixed(4) : "0.0000";
//     return (
//         <>
//             <div className="col-lg-6 col-md-10 col-sm-12">
//                 <div className="heading p-1 mb-3 mt-1 d-flex">
//                     <h4 className='bep_2 mt-1'>Fund Transfer</h4>

//                     <p className='button-background  '>{formattedBalance}</p>
//                 </div>
//                 {/* <form className="login-form">
//                     <div className="mb-3 transfer">
//                         <div className="login-email mt-4">
//                             <label htmlFor="exampleFormControlInput1" className="form-label">User ID</label>
//                             <input type="text" className="input_style form-control" autoComplete='off' placeholder="Enter User ID" value={user_id} onChange={(e) => setUser_id(e.target.value)} />
//                         </div>
//                         <div className="login-password mt-4">
//                             <label htmlFor="exampleFormControlInput1" className="form-label">Amount</label>
//                             <input type='number' className="textarea input_style form-control" autoComplete='off' placeholder="Enter Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
//                             <div className="mb-3 button mt-5">
//                                 <button type="submit" className="login-button" onClick={handleToken}>Transfer</button>
//                             </div>
//                         </div>
//                     </div>
//                 </form> */}
//                 <form className="login-form">
//                     <div className="mb-3 transfer">
//                         <div className="login-email mt-4">
//                             <label htmlFor="user_id" className="form-label">User ID</label>
//                             <input
//                                 type="text"
//                                 className="input_style form-control"
//                                 autoComplete="off"
//                                 placeholder="Enter User IddD"
//                                 value={user_id}
//                                 onBlur={handleUserIdChange} // Use this function to trigger name fetching
//                             />
//                             {name && (
//                                 <p className="mt-2">User Name: {name}</p> // Display the user name
//                             )}
//                         </div>
//                         <div className="login-password mt-4">
//                             <label htmlFor="amount" className="form-label">Amount</label>
//                             <input
//                                 type="number"
//                                 className="textarea input_style form-control"
//                                 autoComplete="off"
//                                 placeholder="Enter Amount"
//                                 value={amount}
//                                 onBlur={(e) => setAmount(e.target.value)}
//                             />
//                             <div className="mb-3 button mt-5">
//                                 <button type="submit" className="login-button" onClick={handleToken}>
//                                     Transfer
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </form>
//             </div>
//         </>
//     )
// }

// export default FundTransfer



import React, { useState, useEffect } from 'react';
import { base_url } from '../config/config';
import _fetch from '../config/api';
import { toast } from 'react-toastify';

const FundTransfer = () => {
    const [user_id, setUser_id] = useState("");
    const [amount, setAmount] = useState("");
    const [wallet, setWallet] = useState();
    const [name, setName] = useState("");
    const [topupfund, setTopupfund] = useState("");
    const handleToken = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }
        if (!amount || !user_id) {
            toast.dismiss();
            toast.error('Please enter the details');
            return;
        }

        try {
            const requestData = {
                user_id,
                amount,
            };

            let response = await fetch(`${base_url}api/token-transfer`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("data", data);

            if (data.status === true) {
                toast.dismiss();
                toast.success(data.message);
                setAmount("");
                setUser_id("");
            } else {
                toast.dismiss();
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error with token transfer:', error);
        }
    };

    const fetchData = async () => {
        try {
            const request = await _fetch(`${base_url}api/dashboard`, 'GET', {}, {});
            if (request.status === true) {
                setWallet(request?.wallet_balance);
                setTopupfund(request?.topup_fund)
            } else {
                toast.error(request.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleBalance = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        try {
            const requestData = {
                sponser_id: user_id.trim(),
            };

            let response = await fetch(`${base_url}api/check_sponser`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                   
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            console.log("data", data);

            if (data.status === true) {
                setName(data.name);

            } else {
                toast.dismiss()
                toast.error(data.message)
                setName("");
            }
        } catch (error) {
            console.error('Error fetching sponsor data:', error);
        }
    };

    const handleUserIdBlur = (e) => {
        const userId = e.target.value.trim();
        setUser_id(userId);
        handleBalance(); // Fetch the name on blur event
    };

    const handleAmountBlur = (e) => {
        setAmount(e.target.value);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formattedBalance = topupfund ? parseFloat(topupfund).toFixed(4) : "0.0000";
console.log('topupfund',topupfund);

    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1 d-flex">
                    <h4 className='bep_2 mt-1'>Fund Transfer</h4>
                    <p className='button-background'>{formattedBalance}</p>
                </div>
                <form className="login-form">
                    <div className="mb-3 transfer">
                        <div className="login-email">
                            <label htmlFor="user_id" className="form-label">User ID</label>
                            <input
                                type="text"
                                className="input_style form-control"
                                autoComplete="off"
                                placeholder="Enter User ID"
                                value={user_id}
                                onBlur={handleUserIdBlur} // Trigger fetching name on blur
                                onChange={(e) => setUser_id(e.target.value)}
                            />
                            {name && (
                                <p className="mt-2"> {name}</p> // Display the user name
                            )}
                        </div>
                        <div className="login-password mt-4">
                            <label htmlFor="amount" className="form-label">Amount</label>
                            <input
                                type="number"
                                className="textarea input_style form-control"
                                autoComplete="off"
                                placeholder="Enter Amount"
                                value={amount}
                                onBlur={handleAmountBlur} // Trigger setting amount on blur
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <div className=" button mt-5">
                                <button type="submit" className="login-button" onClick={handleToken}>
                                    Transfer
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FundTransfer;
