import React, { useEffect, useState , useRef } from 'react';
import _fetch from '../config/api.js';
import { base_url } from '../config/config';
import { display, height, textAlign } from '@mui/system';
import User1 from "../assets/icons/user (4).png"
import User2 from "../assets/icons/user (5).png"
import User3 from "../assets/icons/user (6).png"

const NewTree = () => {
    const elementRef = useRef(null);
    const modalRef = useRef(null);
    const rootNodeRef = useRef(null);  // Add this ref to target the root node
    const treeContainerRef = useRef(null);
    const enterFullScreen = () => {
        if (elementRef.current) {
          if (elementRef.current.requestFullscreen) {
            elementRef.current.requestFullscreen();
          } else if (elementRef.current.mozRequestFullScreen) { // Firefox
            elementRef.current.mozRequestFullScreen();
          } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
            elementRef.current.webkitRequestFullscreen();
          } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
            elementRef.current.msRequestFullscreen();
          }
        }
    };

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
    };

    
    const [showModal, setShowModal] = useState(false);
    const [nodeData, setNodeData] = useState(false);
    const [active, setActive] = useState(0)



    const [data, setData] = useState(null);
    const user_id = localStorage.getItem("user_id");
    const [zoom, setZoom] = useState(1);
    const [treeMemberData, setTreeMemberData] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);
    let [showTreeMember, setTreeMember] = useState(false)
    // const zoomIn = () => setZoom(prevZoom => prevZoom * 1.2);
    // const zoomOut = () => setZoom(prevZoom => Math.max(prevZoom / 1.2, 0.1));
    const [searchId, setSearchId] = useState(''); // State for input search
    const [originalData, setOriginalData] = useState(null); // Store original tree
    const [searchActive, setSearchActive] = useState(false);

    const zoomIn = () => {
        setZoom(prevZoom => {
            const newZoom = prevZoom * 1.2;
            console.log('Zoomed In:', newZoom);
            return newZoom;
        });
    };

    const zoomOut = () => {
        setZoom(prevZoom => {
            const newZoom = Math.max(prevZoom / 1.2, 0.1);
            console.log('Zoomed Out:', newZoom);
            return newZoom;
        });
    };
    let fillTreeMemberData = (data) => {
        setTreeMemberData(data)
        setTreeMember(true)
    }
    let hideTreeMemberData = (data) => {
        setTreeMember(false)
    }
    const handleOpenModal = (nodeData) => {
        setNodeData(nodeData)
        console.log('nodeData ', nodeData)
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const toggleFullScreen = () => {
        console.log('full screen');
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    console.log("user", user_id);

    // Fetch data based on the userId
    const fetchData = async (userId) => {
        try {
            const response = await _fetch(`${base_url}api/member_tree/${userId}`, 'GET', {}, {});
            if (response.status === true) {
                const transformedData = transformData(response.response);
                return transformedData;
            } else {
                console.log('Error: ', response.message || 'Failed to fetch data');
                return null;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    // Fetch initial data on mount
    useEffect(() => {
        fetchData(user_id).then((initialData) => {
            console.log(' initialData ', initialData)
            setData(initialData);
        });
    }, [user_id]);

    // Transforming root-level data
    const transformData = (response) => {
        const rootNode = {
            id: response.user.user_id,
            name: response.user.name,
            detail: response.user.detail,
            paid_status: response.user.paid_status,
            expanded: true,
            children: []
        };

        // Mapping child users under the root node
        let i = 0;
        response.users.forEach(user => {
            const userNode = {
                id: user.user_id,
                name: user.name,
                detail: user.detail,
                paid_status: user.paid_status,
                count : 'node_'+i,
                children: transformSubDirects(user.sub_directs || []),
                expanded: false
            };
            rootNode.children.push(userNode);
            i++;
        });

        return rootNode;
    };

    // Recursive function to transform sub-directs into child nodes
    const transformSubDirects = (subDirects) => {
        console.log('subDirects ', subDirects)
        let i =0;
        return subDirects.map(sub => ({
            id: sub.user_id,
            name: sub.name,
            detail: sub.detail,
            count : 'node_'+i++,
            paid_status: sub.paid_status,
            children: transformLevel3Directs(sub.sub_directs || []),
            expanded: false
        }));
    };

    // Transform third-level directs
    const transformLevel3Directs = (level3Directs) => {
        let i =0;
        return level3Directs.map(level3 => ({
            id: level3.user_id,
            name: level3.name,
            detail: level3.detail,
            count:'node_'+i++,
            children: [],
            expanded: false
        }));
    };

    // Update the node's children and mark it as expanded
    const updateNodeWithNewData = (node, nodeId, newData) => {
        if (node.id === nodeId) {
            return { ...node, children: newData.children, expanded: true };
        }

        if (node.children) {
            return {
                ...node,
                children: node.children.map(child => updateNodeWithNewData(child, nodeId, newData))
            };
        }

        return node;
    };

    // Handle node click to fetch new data and update the tree structure
    const handleNodeClick = async (nodeId) => {
        const newUserData = await fetchData(nodeId);

        if (newUserData) {
            const updatedTree = updateNodeWithNewData(data, nodeId, newUserData);
            setData(updatedTree);
        }
    };

    // Node component
    const Node = ({ node, isRoot }) => {
        const nodeRef = React.useRef(null);

        // Scroll to the node when clicked
        useEffect(() => {
            if (isRoot && nodeRef.current) {
                nodeRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        }, [isRoot]);


        const getUserImage = (node) => {
            console.log('now are checking image 0', node)
            if (node.paid_status == 0) {
                return User1;
            } else if (node.paid_status == 1) {
                return User2;
            } else {
                return User3;
            }
        };

        return (

            <>
                <div className=''>

                    {/* <div className='d-flex'>
                    <div className='item_tree_icon1'>
                         <img className='user02' src={User1} />
                        <span className='user_nam'>Active</span>
                    </div>
                    <div className='item_tree_icon2'>
                        <img className='user02' src={User2} />
                        <span className='user_nam'>Inactive</span>
                    </div>
                    <div className='item_tree_icon3'>
                        <img className='user02' src={User3} />
                        <span className='user_nam'>Empty</span>
                    </div>
                </div> */}
                </div>

                <div className="node-card" >
                    <div className='tree_main_b'>
                        <div className="node-header hover-container">
                            {/* <img src="https://user.infinitemlmsoftware.com/images/user-profile.png" style={{height:'60px',borderRadius:'50%',width:'60px',overflow:'hidden'}}/> */}
                            <img
                                src={getUserImage(node)} className='tree_user_img'
                                // style={{ height: '60px', borderRadius: '50%', width: '60px', overflow: 'hidden' }}
                            />
                            <div className='d-flex '>
                                <h6 className='id_tree' onClick={() => handleOpenModal(node)}>{node.id}</h6> 
                                <br/>
                                <button type="button"  className="btn btn-primary">
                                    <i  onClick={() => handleNodeClick(node.id)}  class="fa-solid fa-circle-plus fa-beat-fade"></i>
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </>


        );
    };


    const renderTree = (node, isRoot = false) => {
        console.log('tree data ', node)
        if (!node) return null;

        
        return (
            <div ref={isRoot ? rootNodeRef : null} className={node.count}>
                <div key={node.id} className="node-container">
                    <Node node={node} isRoot={isRoot} />
                    {node.expanded && node.children && node.children.length > 0 && (
                        <div className="children-container">
                            {node.children.map(child => renderTree(child))}
                        </div>
                    )}
                </div>
            </div>

        );
    };

    let treeMemberInfo = () => {
        return (
            <div class="profile-card inner-div" style={{ display: showTreeMember == true ? 'block' : 'none' }}>
                <img src="https://user.infinitemlmsoftware.com/images/user-profile.png" alt="Profile Picture" />
                <div class="profile-info">
                    <div class="profile-name">{treeMemberData?.name}</div>
                    <div class="profile-subtitle">{treeMemberData?.id}</div>
                </div>
                <table className='treeData table table-border table-responsive' style={{ fontSize: 'small', text: 'dark' }}>
                    <tr>
                        <td><span className='bottom-table-span'>Package:</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.package_amount}</span></td>
                        <td><span className='bottom-table-span'>DirectBusiness:</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.direct_business}</span></td>
                    </tr>
                    <tr>
                        <td><span className='bottom-table-span'>LeftBusiness (CVT):</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.leftbusiness_cvt}</span></td>
                        <td><span className='bottom-table-span'>RightBusiness (CVT):</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.rightbusiness_cvt}</span></td>
                    </tr>
                    <tr>
                        <td><span className='bottom-table-span'>LeftBusiness  (USDT):</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.leftbusiness_usdt}</span></td>
                        <td><span className='bottom-table-span'>RightBusiness (USDT):</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.rightbusiness_usdt}</span></td>
                    </tr>
                    <tr>
                        <td><span className='bottom-table-span'>Left Paid IDs:</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.leftpaid_ids}</span></td>
                        <td><span className='bottom-table-span'>Right Paid IDs):</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.rightpaid_ids}</span></td>
                    </tr>
                    <tr>
                        <td><span className='bottom-table-span'>Left UnPaid IDs:</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.leftunpaid_ids}</span></td>
                        <td><span className='bottom-table-span'>Right UnPaid IDs:</span></td>
                        <td><span className='bottom-table-span'>{treeMemberData?.detail?.rightunpaid_ids}</span></td>
                    </tr>
                </table>
            </div>
        );
    }
    if (!data) {
        return <div>Loading...</div>;
    }

    const scrollToTopCenter = () => {
        if (treeContainerRef.current) {
          const container = treeContainerRef.current;
          const containerWidth = container.scrollWidth; // Full width of the scrollable content
          const containerHeight = container.scrollHeight; // Full height of the scrollable content
          const viewportWidth = container.clientWidth;  // Visible width of the container
    
          // Scroll to the center horizontally and top vertically
          container.scrollTo({
            top: 0,  // Go to the top
            left: (containerWidth - viewportWidth) / 2,  // Center horizontally
            behavior: 'smooth',
          });
        }
      }
    const handleSearch = async () => {
        if (searchId) {
            try {
                const response = await _fetch(`${base_url}api/member_tree/${searchId}`, 'GET', {}, {});
                if (response.status === true) {
                    const transformedData = transformData(response.response);
                    if (!searchActive) setOriginalData(data); 
                    setData(transformedData);
                    setSearchActive(true);
                } else {
                    console.log('Error: ', response.message || 'No user found with that ID');
                }
            } catch (error) {
                console.error('Error searching data:', error);
            }
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchId(e.target.value);
    };
    const handleBackToTree = () => {
        if (searchActive && originalData) {
            setData(originalData);
            setSearchActive(false);
            setSearchId(''); // Clear search input
        }
    };

    const scrollToTop = () => {
        // if (rootNodeRef.current) {
        //     rootNodeRef.current.scrollIntoView({
        //         behavior: 'smooth',
        //         block: 'center',
        //         // inline: 'center',
        //         // textAlign:"center",
        //     });
        // }
          window.location.reload();
    };

    return (
        <>
              <div className='box_fix_tree'>
                <div className='d-flex aie'>
                <div className='item_tree_icon1'>
                        <img className='user02' src={User2} />
                        <span className='user_nam'>Active</span>
                    </div>
                    <div className='item_tree_icon2'>
                         <img className='user02' src={User1} />
                        <span className='user_nam'>Inactive</span>
                    </div>
                    
                    <div className='item_tree_icon3'>
                        <img className='user02' src={User3} />
                        <span className='user_nam'>Empty</span>
                    </div>
                </div>
               
                 <div className='tree_btn_sec'>
                {/* Search input and button */}
                <form className="d-flex me-auto" role="search">
                    <div className="search-container02 d-flex">
                        <input 
                            className="tree_sec" 
                            type="search" 
                            placeholder="Search your downline" 
                            aria-label="Search" 
                            value={searchId}
                            onChange={handleSearchInputChange} 
                        />
                        <button 
                        type="button" 
                        className="sec_icon" 
                        onClick={handleSearch} 
                    >
                        <i className="fa fa-search"></i>
                    </button>
                    </div>
                    
                </form>

                
                {searchActive && (
                    <button 
                        type="button" 
                        className="back_home" 
                        onClick={handleBackToTree} // Trigger back to original tree
                    >
                        <i className="fa fa-home"></i> Back to Home Tree
                    </button>
                )}
                 </div>
               </div>


        <div className='tree_over'>
            <div className="tree-container mt-5"  style={{ transform: `scale(${zoom})` ,background:'transparent' }}>

                <div className="tree">
                    <div className="org-chart">
                        {renderTree(data, true)}
                    </div>
                </div>
                
                {/* <button onClick={scrollToTop} className="scroll-to-top-btn">
                <i className="fas fa-arrow-up"></i> 
            </button> */}
            </div>

            {/* popup */}

            {showModal && (
                <div className="modal show" tabindex="-1" style={{ display: 'block' }} onClick={handleCloseModal}>
                    <div className="modal-dialog" onClick={(e) => e.stopPropagation()} >
                        <div className="modal-content popup_con">
                        {/* <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"><i class="fa-solid fa-xmark"></i></button> */}
                           
                            <div className='popup_tree01'>
                                <div className='d-flex tree_icon_box'>
                                    <div class="profile-inf">
                                        <div class="profile-name">{nodeData?.name}</div>
                                        <div class="profile-subtitle">{nodeData?.id}</div>
                                    </div>
                                    <div>
                                        <img className='popup_img' src="https://user.infinitemlmsoftware.com/images/user-profile.png" alt="Profile Picture" />
                                    </div>
                                </div>
                                <ul class="list-group" style={{ fontSize: '0.6em', background: 'transparent', textAlign: 'left' }}>
                                    <li class="list-group-item treeDataDisplay"> <span> Package :</span> <span>{nodeData?.detail?.package_amount}</span></li>
                                    <li class="list-group-item treeDataDisplay"><span>Direct Business :</span><span>{nodeData?.detail?.direct_business}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Left Business (CVT) :</span><span>{nodeData?.detail?.leftbusiness_cvt}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Right Business (CVT) :</span><span>{nodeData?.detail?.rightbusiness_cvt}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Left Business  (USDT) :</span><span>{nodeData?.detail?.leftbusiness_usdt}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Right Business (USDT) :</span><span>{nodeData?.detail?.rightbusiness_usdt}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Left Paid IDs :</span><span>{nodeData?.detail?.leftpaid_ids}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Right Paid IDs :</span><span>{nodeData?.detail?.rightpaid_ids}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Left UnPaid IDs :</span><span>{nodeData?.detail?.leftunpaid_ids}</span> </li>
                                    <li class="list-group-item treeDataDisplay"><span>Right UnPaid IDs :</span><span>{nodeData?.detail?.rightunpaid_ids}</span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className="controls">
            {/* <button onClick={zoomIn}>
                <i className="fas fa-plus"></i>
            </button>
            <button onClick={zoomOut}>
                <i className="fas fa-minus"></i>
            </button> */}
            <button onClick={scrollToTop} className="scroll-to-top-btn">
                <i className="fas fa-arrow-up"></i> 
            </button>
            {/* <button onClick={toggleFullScreen}>
                <i className="fas fa-expand"></i>
            </button> */}
        </div>
        </>


    );
};

export default NewTree;