import React, { useState } from 'react';
import logo from "../assets/images/cvt logo web.png";
import dashboard from "../assets/icons/dashboard_icon.png";
import fund from "../assets/icons/fund_icon.png";
import staking from "../assets/icons/staking_icon.png";
import bonus from "../assets/icons/bonus_icon.png";
import community from "../assets/icons/community_icon.png";
import withdrawal from "../assets/icons/withdrawal_icon.png";
import fund_transfer from "../assets/icons/fund transfer_icon.png";
import help from "../assets/icons/help_icon.png";
import facebook from "../assets/icons/facebook_icon.png";
import whatsapp from "../assets/icons/whatsapp_icon.png";
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isSidebarOpen,handleSidebarToggle }) => {
    const [openSection, setOpenSection] = useState(null);
    const [activeSection, setActiveSection] = useState(null);

    const handleSectionToggle = (section) => {
        if (activeSection === section) {
            setActiveSection(null);
        } else {
            setActiveSection(section);

        }
        setOpenSection(openSection === section ? null : section);

    };


    const handleNavLinkClick = () => {
        handleSidebarToggle(); // Automatically close sidebar on NavLink click
    }
    const handleClick = (section, event) => {
        handleSectionToggle(section);
        handleNavLinkClick(event);
    };

    return (
        <>
            <div className="sidebarViewport_aRkj">
                <div className={`sidebar  min-vh-100 d-flex justify-content-between flex-column ${isSidebarOpen ? 'open' : 'closed'}`}>
                    <nav aria-label="Docs sidebar" className='menu thin-scrollbar menu_SIkG m-2'>
                        <div className="app m-1">
                            <div className="logo-img ms-3 m-4 mb-3">
                                <img src={logo} alt='cv token' />
                            </div>
                            <ul className='nav nav-pills flex-column mt-sm-0 slide'>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'dashboard' ? 'active' : ''}`}>
                                    <NavLink to="/" className='link' onClick={(e) => handleClick('dashboard', e)}>
                                        <span className='nav-link text-white fs-5' aria-current='page'>
                                            <img src={dashboard} alt='dashboard_icon' />
                                            <span className='ms-2'>Dashboard</span>
                                        </span>
                                    </NavLink>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'fundManagement' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('fundManagement')}>
                                       <span className='d_f_s'>
                                       <span>
                                        <img src={fund} alt='fund_icon' />
                                        <span className='ms-2'>Fund Management</span>
                                        </span> 
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style mt-2 list-unstyled ${openSection === 'fundManagement' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/deposit_bep' className='nav-link-style' onClick={handleNavLinkClick}> <span>Deposit BEP 20</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/deposit_history' className='nav-link-style' onClick={handleNavLinkClick}> <span> USDT Deposit History</span></NavLink>
                                            </li>

                                            <li className="nav-item-submenu">
                                                <NavLink to='/token_deposit' className='nav-link-style' onClick={handleNavLinkClick} ><span>CVT Deposit History</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/wallet_ledger' className='nav-link-style' onClick={handleNavLinkClick} ><span>Wallet Ledger</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'staking' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('staking')}>
                                    <span className='d_f_s'>
                                        <span>
                                            <img src={staking} alt='staking_icon' />
                                            <span className='ms-2'>Staking</span>
                                        </span>
                                        <span>
                                        <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                    </span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'staking' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/stake_token' className='nav-link-style' onClick={handleNavLinkClick}><span>Stake Tokens</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/stake_token_history' className='nav-link-style' onClick={handleNavLinkClick}><span>Stake Token History</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'Bonus Portfolio' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Bonus Portfolio')}>
                                    <span className='d_f_s'>
                                        <span>  
                                            <img src={bonus} alt='bonus portfolio_icon' />
                                            <span className='ms-2'>Bonus Portfolio</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'Bonus Portfolio' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/daily_staking_income' className='nav-link-style' onClick={handleNavLinkClick} ><span>Daily Staking Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/direct_income' className='nav-link-style' onClick={handleNavLinkClick}><span>Direct Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/matrix_income' className='nav-link-style'onClick={handleNavLinkClick} ><span>Matrix Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/rewards_income' className='nav-link-style'onClick={handleNavLinkClick} ><span>Rewards Income</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/income_ledger' className='nav-link-style'onClick={handleNavLinkClick} ><span>Income Ledger</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/starclub_bonus' className='nav-link-style'onClick={handleNavLinkClick} ><span>Starclub Bonus</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/leadership_bonus' className='nav-link-style' onClick={handleNavLinkClick}><span>Leadership Bonus</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/refferalbonus' className='nav-link-style'onClick={handleNavLinkClick} ><span>EPS Referral Bonus</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'Community' ? 'active' : ''}`}>
                                    <NavLink to='' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Community')}>
                                    <span className='d_f_s'>
                                        <span>  
                                        <img src={community} alt='community_icon' />
                                        <span className='ms-2'>Community</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style list-unstyled ${openSection === 'Community' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/direct_referral' className='nav-link-style' onClick={handleNavLinkClick}><span>Direct Referrals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/tree_chart' className='nav-link-style'onClick={handleNavLinkClick} ><span>Tree</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/genelogy' className='nav-link-style' onClick={handleNavLinkClick}><span>Genelogy</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/matrix_downline' className='nav-link-style' onClick={handleNavLinkClick}><span>Matrix Downline List</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/self_downline' className='nav-link-style' onClick={handleNavLinkClick}><span>Self Downline List</span></NavLink>
                                            </li>
                                        </ul>
                                    </NavLink>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'Withdrawal' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Withdrawal')}>
                                    <span className='d_f_s'>
                                        <span>  
                                        <img src={withdrawal} alt='withdrawal_icon' />
                                        <span className='ms-2'>Withdrawal</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style   list-unstyled ${openSection === 'Withdrawal' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/cvt_withdrawal' className='nav-link-style'onClick={handleNavLinkClick}><span> Withdrawal</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/cvt_withdrawals_history' className='nav-link-style'onClick={handleNavLinkClick}><span> Withdrawals History</span></NavLink>
                                            </li>
                                            {/* <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawal' className='nav-link-style'><span>USDT Withdrawals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawals_history' className='nav-link-style'><span>USDT Withdrawals History</span></NavLink>
                                            </li> */}
                                        </ul>
                                    </a>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'fundTransfer' ? 'active' : ''}`}>
                                    <NavLink to='/fund_transfer' className='nav-link fs-5' aria-current='page' onClick={(e) => {handleClick('fundTransfer', e); }} >
                                    <span className='d_f_s'>
                                        <span> 
                                        <img src={fund_transfer} alt='fund transfer_icon' />
                                        <span className='ms-2'>Fund Transfer</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                    </NavLink>
                                    
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'Report' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link fs-5' aria-current='page' onClick={() => handleSectionToggle('Report')}>
                                    <span className='d_f_s'>
                                        <span>  
                                        <img src={withdrawal} alt='withdrawal_icon' />
                                        <span className='ms-2'>Report</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style   list-unstyled ${openSection === 'Report' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/ebalance' className='nav-link-style'onClick={handleNavLinkClick}><span> Team Business Report</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/usdtreport' className='nav-link-style'onClick={handleNavLinkClick}><span> Team USDT Deposit Report</span></NavLink>
                                            </li>
                                            {/* <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawal' className='nav-link-style'><span>USDT Withdrawals</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/usdt_withdrawals_history' className='nav-link-style'><span>USDT Withdrawals History</span></NavLink>
                                            </li> */}
                                        </ul>
                                    </a>
                                </li>
                                <li className={`nav-item text-white fs-4 my-1 py-2 py-sm-0 ${activeSection === 'help' ? 'active' : ''}`}>
                                    <a href='#' className='nav-link  fs-5' aria-current='page' onClick={() => handleSectionToggle('help')}>
                                    <span className='d_f_s'>
                                        <span> 
                                        <img src={help} alt='help desk_icon' />
                                        <span className='ms-2'>Help Desk</span>
                                        </span>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                        </span>
                                        <ul className={`nav-link-style   list-unstyled ${openSection === 'help' ? 'show' : 'collapse'}`}>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/create_ticket' className='nav-link-style' onClick={handleNavLinkClick}><span>Create Ticket</span></NavLink>
                                            </li>
                                            <li className="nav-item-submenu">
                                                <NavLink to='/ticket_list' className='nav-link-style'onClick={handleNavLinkClick}><span>Ticket List</span></NavLink>
                                            </li>
                                        </ul>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    {/* <div className="box m-1">
                        <div className="box1">
                            <div className="box2">
                                <span>Referral Link</span>
                            </div>
                            <div className="d-flex gap-3 p-2 mb-2">
                                <img src={whatsapp} alt='whatsapp' />
                                <img src={facebook} alt='facebook' />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Sidebar;