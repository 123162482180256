// import React from 'react'

// const Refferalbonus = () => {
//   return (
//     <>
//     </>
//   )
// }

// export default Refferalbonus

// import React, { useEffect, useState } from 'react'
// import _fetch from '../config/api';
// import { base_url } from '../config/config'

// const Refferalbonus = () => {

//     const [leader, setLeader] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const [per_page] = useState(10);
//     const [totalPages, setTotalPages] = useState(0);

//     useEffect(() => {
//         fetchData();
//     }, [])

//     console.log('leader-=-=-',leader);


//     const fetchData = async () => {
//         try {
//             const request = await _fetch(`${base_url}api/eps_refferal_bonus?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {})
//             // console.log("level-income", request);
//             if (request.status === true) {
//                 setLeader(request.data)
//             } else {

//             }
//             // setCurrentPage(parseInt(request.pagination.current_page, 10));
//             // setTotalPages(parseInt(request.pagination.total_pages, 10));

//         } catch (error) {
//             console.log("Error fetching data:", error);
//         }
//     }


//     return (
//         <>


//             <div className="onew p-3">
//                 <div className="heading">
//                     <h4 className='bep_2'> Referral Bonus</h4>
//                 </div>
//                 <div className="rounded">
//                     <div className="table-responsive mt-3">
//                         <table class="table">
//                             <thead className='table-heading'>
//                                 <tr>
//                                     <th scope="col">Sr.No.</th>
//                                     <th scope="col">EPS</th>
//                                     <th scope="col">Income</th>
//                                     <th scope="col">Description</th>
//                                     <th scope="col">Date</th>
//                                     {/* <th scope="col">Action</th> */}
//                                 </tr>
//                             </thead>
//                             <tbody className='tb_colo'>
//                                 {leader && leader.map((item, index) => {
//                                     return (
//                                         <tr key={index}>
//                                             <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
//                                             <td scope='row'>{item.EPS}</td>
//                                             <td scope='row'>{item.Income}</td>
//                                             <td scope='row'>{item.Description}</td>
//                                             <td scope='row'>{item.Date}</td>
//                                             {/* <td scope='row'>{item.action}</td> */}
//                                         </tr>
//                                     )
//                                 })}
//                             </tbody>
//                         </table>
//                         <div className="pagination-custom">
//                             <nav aria-label="Pagination fgh">
//                                 <ul className="pagination">
//                                     <li className="page-item previous">
//                                         <a className="page-link" href="#" aria-label="Previous">
//                                             <span aria-hidden="true">&larr;</span> Previous
//                                         </a>
//                                     </li>
//                                     <div className="page-numbers">
//                                         <li className="page-item active"><a className="page-link" href="#">1</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">2</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">3</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">...</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">8</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">9</a></li>
//                                         <li className="page-item"><a className="page-link" href="#">10</a></li>
//                                     </div>
//                                     <li className="page-item next">
//                                         <a className="page-link" href="#" aria-label="Next">
//                                             Next <span aria-hidden="true">&rarr;</span>
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </nav>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Refferalbonus
import React, { useEffect, useState } from 'react';
import _fetch from '../config/api';
import { base_url } from '../config/config';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
const Refferalbonus = () => {
    const [leader, setLeader] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState('')
    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/eps_refferal_bonus?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});
            if (request.status === true) {
                setLeader(request.data);
                setBalance(request?.income_balance)
                setTotalPages(parseInt(request.pagination.total_pages, 10));
                setTotalRecords(parseInt(request.pagination.total_records, 10));
                setLoading(false);
            } else {
                toast.dismiss()
                toast.error(request.message)
                setLoading(false);
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    };
    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };

    const formattedBalance = balance?.balance ? parseFloat(balance.balance).toFixed(4) : "0.0000";

    return (
        <>
            <div className="onew p-1">
                <div className="heading  d-flex ">
                    <h4 className='bep_2 mt-1'>Referral Bonus</h4>
                    <p className='button-background  '>{formattedBalance}</p>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                        {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                            <table className="table">
                                <thead className='table-heading'>
                                    <tr>
                                        <th scope="col">Sr.No.</th>
                                        <th scope="col">EPS</th>
                                        <th scope="col">Income</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody className='tb_colo'>
                                    {leader && leader.length > 0 ? (
                                        leader.map((item, index) => (
                                            <tr key={index}>
                                                <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                                <td>{item.EPS}</td>
                                                <td>{item.Income}</td>
                                                <td>{item.Description}</td>
                                                <td>{item.Date}</td>
                                            </tr>
                                        ))) : (
                                        <tr>
                                            <td scope='row' colSpan={6} style={{ textAlign: 'center' }}>No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}
                        <div className="pagination-custom">
                            <nav aria-label="Pagination">
                                <ul className="pagination justify-content-center">

                                    {totalPages > 1 && (
                                        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                <span aria-hidden="true">&larr;</span> Previous
                                            </a>
                                        </li>
                                    )}


                                    <div className="page-numbers">
                                        {renderPageNumbers().map((page, index) => (
                                            <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                {page === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                        {page}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </div>


                                    {totalPages > 1 && (
                                        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handleNextClick}>
                                                Next <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Refferalbonus;
