import React, { useEffect, useState } from 'react'
import { base_url } from '../config/config';
import _fetch from '../config/api';
import toast from 'react-hot-toast';
import { useLocation, useRoutes } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';

const Matrixdownlinedetail = (props) => {

    const [matrixDownline, setMatrixDownline] = useState([]);
    const { state } = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    // console.log('state', state.level);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchDownline()
    }, [currentPage])


    const fetchDownline = async () => {
        setLoading(true)
        if (!state?.level) {
            return console.log('level not found!');
        }
        try {
            setLoading(true)
            const request = await _fetch(`${base_url}api/level_details/${state?.level}?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {});
            console.log('request------>', request);

            // setMatrixDownline(request.data)
            // setLoading(false)


            if (request.status === true) {
                setMatrixDownline(request.data)

                setTotalPages(parseInt(request.pagination.total_pages, 10));
                setTotalRecords(parseInt(request.pagination.total_records, 10));
                setLoading(false)
            } else {
                setLoading(false)
                toast.dismiss()
                toast.error(request.message)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)

        }

    }
    const handlePageClick = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handlePreviousClick = (e) => {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        const maxPageNumbersToShow = 5;

        if (totalPages <= maxPageNumbersToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, 4, '...', totalPages);
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
            }
        }
        return pages;
    };

    return (
        <>

            <div className="onew p-3">
                <div className="heading">
                    <h4 className='bep_2'>Matrix Downline List</h4>
                </div>
                <div className="rounded">
                    <div className="table-responsive mt-3">
                    {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                        <table class="table">
                            <thead className='table-heading'>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">User Id</th>
                                    <th scope="col">Name</th>
                                    {/* <th scope="col">User Id</th> */}
                                    <th scope="col">Sponsor ID</th>
                                    <th scope="col">Placement ID</th>
                                    <th scope="col">Package</th>
                                    <th scope="col">Level</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Topup Date</th>
                                </tr>
                            </thead>
                            <tbody className='tb_colo'>
                                {matrixDownline && matrixDownline.length > 0 ? (
                                matrixDownline.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                         <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                         <td scope='row'>{item.user_id}</td>
                                            <td scope='row'>{item.name}</td>
                                            {/* <td scope='row'>{item.user_id}</td> */}
                                            <td scope='row'>{item.sponsor_id}</td>
                                            <td scope='row'>{item.placement_id}</td>
                                            <td scope='row'>{item.package}</td>
                                            <td scope='row'>{item.level}</td>
                                            <td scope='row'>{item.date}</td>
                                            <td scope='row'>{item.topup_date}</td>
                                        </tr>
                                    )
                                })) : (
                                    <tr>
                                        <td scope='row'  colSpan={6} style={{ textAlign: 'center' }}>No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                            )}
                           <div className="pagination-custom">
                            <nav aria-label="Pagination">
                                <ul className="pagination justify-content-center">

                                    {totalPages > 1 && (
                                        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handlePreviousClick}>
                                                <span aria-hidden="true">&larr;</span> Previous
                                            </a>
                                        </li>
                                    )}


                                    <div className="page-numbers">
                                        {renderPageNumbers().map((page, index) => (
                                            <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                                {page === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <a className="page-link" href="#" onClick={(e) => handlePageClick(e, page)}>
                                                        {page}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </div>


                                    {totalPages > 1 && (
                                        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                                            <a className="page-link" href="#" onClick={handleNextClick}>
                                                Next <span aria-hidden="true">&rarr;</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Matrixdownlinedetail