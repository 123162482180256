    // import React, { useEffect, useState } from 'react'
    // import { NavLink, useNavigate } from 'react-router-dom';
    // import user_icon from "../assets/icons/user.png";
    // import _fetch from '../config/api'
    // import { base_url } from '../config/config'
    // import { toast } from 'react-toastify';

    // const Header = ({ handleSidebarToggle, isSidebarOpen, userName, userId }) => {
    //     const [profileName, setProfileName] = useState("");
    //     const [user_id, setUser_id] = useState("");
    //     const navigate = useNavigate();

    //     useEffect(() => {
    //         fetchProfile();
    //     }, [])

    //     const fetchProfile = async () => {
    //         try {
    //             const request = await _fetch(`${base_url}api/profile`, 'GET', {}, {});
    //             console.log('request------>', request);
    //             if (request.status === true) {
    //                 setProfileName(request?.user_data?.name)
    //                 setUser_id(request?.user_info?.user_id)
    //             } else {
    //                 toast.error(request.message)
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     }





    //     const logout = () => {
    //         localStorage.clear('token');
    //         navigate('/login');
    //     };

    //     const handleLinkClick = () => {
    //         if (isSidebarOpen) {
    //             handleSidebarToggle();
    //         }
    //     };

    //     return (
    //         <>
    //             <nav className="col-lg-12 navbar navbar-expand-lg">
    //                 <div className="container-fluid">
    //                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //                         <span className="navbar-toggler-icon" />
    //                     </button>

    //                     <button className="sidebar-toggle btn btn-primary d-lg-none" type='button' onClick={handleSidebarToggle}>
    //                         <span className="navbar-toggler-icon" />
    //                     </button>

    //                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //                         <form className="d-flex me-auto" role="search">
    //                             <div className="search-container">
    //                                 <i className="bi bi-search search-icon"></i>
    //                                 <input className="form-control input_form_control" type="search" placeholder="Search" aria-label="Search" />
    //                             </div>
    //                         </form>

    //                         <div className='header-user-icon'>
    //                             <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
    //                                 <li className="nav-item-pic">
    //                                     <div className="icons-header">
    //                                         <img src={user_icon} alt='user' />
    //                                     </div>
    //                                 </li>
    //                                 <li className="nav-item dropdown">
    //                                     <a href='#' className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //                                         <span className='pro-max'>{profileName}</span>  <br />
    //                                         {/* <small>{profileName}</small> */}
    //                                         <small>{user_id}</small>
    //                                     </a>
    //                                     <ul className="dropdown-menu">
    //                                         <li>
    //                                             <NavLink to='/account' className="dropdown-item" onClick={handleLinkClick}>
    //                                                 My Account
    //                                             </NavLink>
    //                                         </li>
    //                                         <li>
    //                                             <a className="dropdown-item" href="#" onClick={() => { logout(); handleLinkClick(); }}>
    //                                                 Log Out
    //                                             </a>
    //                                         </li>
    //                                     </ul>
    //                                 </li>
    //                             </ul>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </nav>
    //         </>
    //     );
    // };

    // export default Header;




    import React, { useEffect, useState } from 'react';
    import { NavLink, useNavigate } from 'react-router-dom';
    import user_icon from "../assets/icons/user.png";
    import _fetch from '../config/api';
    import { base_url } from '../config/config';
    import { toast } from 'react-toastify';
    import logo from "../assets/images/cvt logo web.png";

    const Header = ({ handleSidebarToggle, isSidebarOpen }) => {
        const [profileName, setProfileName] = useState("");
        const [user_id, setUser_id] = useState("");
        const navigate = useNavigate();

        useEffect(() => {
            fetchProfile();
        }, []);

        const fetchProfile = async () => {
            try {
                const request = await _fetch(`${base_url}api/profile`, 'GET', {}, {});
                if (request.status === true) {
                    setProfileName(request?.user_data?.name);
                    setUser_id(request?.user_info?.user_id);
                } else {
                    toast.error(request.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const logout = () => {
            localStorage.clear('token');
            navigate('/login');
        };

        const handleLinkClick = () => {
            if (isSidebarOpen) {
                handleSidebarToggle();
            }
        };

        return (
            <>
                <nav className="col-lg-12 navbar navbar-expand-lg web_d">
                    <div className="container-fluid">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>

                        <button className="sidebar-toggle btn btn-primary d-lg-none" type='button' onClick={handleSidebarToggle}>
                            <span className="navbar-toggler-icon" />
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <form className="d-flex me-auto sc_form" role="search">
                                <div className="search-container d-flex">
                                    <i className="bi bi-search search-icon"></i>
                                    <input className="serch_form_h" type="search" placeholder="Search" aria-label="Search" />
                                </div>
                            </form>

                            <div className='header-user-icon'>
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                                    <li className="nav-item">
                                        <div className="icons-header">
                                            <img src="https://user.infinitemlmsoftware.com/images/user-profile.png" alt='user' className="user-icon" />
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a href='#' className="nav-link dropdown-toggle d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="user-info-header">
                                                <span className='asho'>{profileName}</span><br />
                                                <small className='roll'>{user_id}</small>
                                            </div>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <NavLink to='/account' className="dropdown-item" onClick={handleLinkClick}>
                                                    My Account
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#" onClick={() => { logout(); handleLinkClick(); }}>
                                                    Log Out
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>
                                            <i class="fa-solid fa-angle-down w_c"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>





                <div className='d-flex phon_header phon_d d-lg-none'>
                    <div className='phone_logo'>
                  <NavLink to='/' > <img className='phone_logo' src={logo} alt='cv token' /></NavLink>
                        {/* <img className='phone_logo' src={logo} alt='cv token' /> */}
                    </div>

                    <div className='d-flex phone_head_d'>
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                                
                                    <li className="nav-item dropdown">
                                        <a href='#' className="nav-link dropdown-toggle d-flex align-items-center" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className='phone_id025'>{user_id}</span>
                                            <img src="https://user.infinitemlmsoftware.com/images/user-profile.png" alt='user' className="user-icon_head" />
                                        </a>
                                        <ul className="dropdown-menu head_dropdown">
                                            <li>
                                                <NavLink to='/account' className="dropdown-item" onClick={handleLinkClick}>
                                                    My Account
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#" onClick={() => { logout(); handleLinkClick(); }}>
                                                    Log Out
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                        <button className="sidebar-toggle btn btn-primary d-lg-none phone_tgl_1" type='button' onClick={handleSidebarToggle}>
                            {/* <span className="navbar-toggler-icon" /> */}
                            <i class="fa-solid fa-list-ul"></i>
                        </button>   
                    </div>
                </div>
            </>
        );
    };

    export default Header;

