


import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { base_url } from '../config/config';
import { toast } from 'react-toastify';
import logoPic from '../assets/images/cvt logo web.png';

const Otps = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure the data passed from the signup page
    const { rbc_address } = location.state || {};

    // Create refs for each OTP input field
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    // Handle OTP submission
    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        const enteredOtp = otp.join('');
        if (enteredOtp.length !== 6) {
            toast.error('Please enter a valid 6-digit OTP');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const requestData = {
                rbc_address,
                otp: enteredOtp,
            };

            const response = await fetch(`${base_url}api/update-address`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            });

            const data = await response.json();
            // console.log("Registration API Response:", data);

            if (data.status === true) {
                toast.dismiss();
                toast.success(data.message);
                navigate('/cvt_withdrawal'); // Redirect to login page on success
                setOtp(['', '', '', '', '', '']);
                // nav(igate('/'); // Redirect to login page on success
                setOtp('')
            } else {
                toast.dismiss();
                toast.error(data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    // Handle OTP resend
    // const handleResendOtp = async () => {
    //     try {
    //         const requestData = {
    //             email,
    //             sponser_id,
    //         };

    //         const response = await fetch(`${base_url}api/generate_register_otp`, {
    //             method: 'POST',
    //             mode: 'cors',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(requestData),
    //         });

    //         const data = await response.json();
    //         console.log("Resend OTP API Response:", data);

    //         if (data.status === true) {
    //             toast.success('OTP resent successfully');
    //         } else {
    //             toast.error(data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error during OTP resend:', error);
    //         toast.error('Failed to resend OTP');
    //     }
    // };

    // Function to move focus to the next input
    const focusNext = (index) => {
        if (index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
    };

    // Handle input change
    const handleOtpChange = (index, value) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);

        if (value.length === 1) {
            focusNext(index);
        }
    };

    // Handle pasting
    const handlePaste = (e) => {
        const pastedValue = e.clipboardData.getData('text');
        const otpArray = pastedValue.split('').slice(0, 6); // Take only the first 6 characters
        const updatedOtp = [...otpArray, ...otp.slice(otpArray.length)]; // Fill the OTP array
        setOtp(updatedOtp);

        // Focus on the next empty input
        for (let i = 0; i < updatedOtp.length; i++) {
            if (updatedOtp[i] === '') {
                inputRefs[i].current.focus();
                break;
            }
        }
    };

    return (
        <div className="page">
            <div className="first_account py-3">

            </div>
            <div className="row justify-content-center align-items-center mt-5">
                <div className="col-lg-5 col-md-9 col-sm-10 m-5">
                    <form className="otp-form text-center" onSubmit={handleSubmitOtp}>
                        <div className="mb-3 otp-ju">
                            <div className="heading mt-4">
                                <h4 className="bep_2">Enter OTP</h4>
                            </div>
                            <div className="mt-4">
                                {otp && otp?.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        className="otp-input"
                                        maxLength={1}
                                        value={digit}
                                        onInput={(e) => handleOtpChange(index, e.target.value)}
                                        onPaste={handlePaste} // Handle paste
                                        style={styles.input}
                                    />
                                ))}
                            </div>
                            <div className="ios">
                                <button type="submit" className="login-button">Submit</button>
                                {/* <button type="button" className="login-button iosb" onClick={handleResendOtp}>Resend</button> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};


const styles = {
    input: {
        width: '35px',
        height: '35px',
        fontSize: '15px',
        textAlign: 'center',
        marginRight: '10px',
    },
};

export default Otps;
