import React, { useState } from 'react'
import { base_url } from '../config/config';
import Createtickettable from './Createtickettable'
const CreateTicket = () => {

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");


    const handleTicket = async (e) => {

        e.preventDefault();

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token not found");
            return;
        }

        // console.log("token", token);

        try {
            const requestData = {
                title,
                message,
            };

            let response = await fetch(`${base_url}api/compose-mail`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": token,
                },
                body: JSON.stringify(requestData),
            })

            const data = await response.json();
            console.log("data", data);

            setTitle(""); // Reset title
            setMessage(""); // Reset message


        } catch (error) {
            console.error('Error with email login:', error);
        }
    }


    return (
        <>
            <div className="col-lg-6 col-md-10 col-sm-12">
                <div className="heading p-1 mb-3 mt-1">
                    <h4 className='bep_2'>Create Ticket</h4>
                </div>
                <form className="login-form">
                    <div className="mb-3 bg_1">
                        <div className="login-email">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Topic</label>
                            <select className='input_style_lj form-control' id="topicSelect" autoComplete='off'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)} >
                                <option value="" disabled>Select Topic</option>
                                <option value="General">General</option>
                                <option value="Withdraw">Withdraw</option>
                                <option value="Topup">Topup</option>
                            </select>
                        </div>
                        <div className="login-password mt-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                            <textarea className="textarea input_style form-control" autoComplete='off' placeholder="Enter Message" rows="6" value={message}
                                onChange={(e) => setMessage(e.target.value)} />
                            <div className="mb-3 button mt-4">
                                <button type="submit" className="login-button" onClick={handleTicket}>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='row'>
            <Createtickettable/>
</div>
        </>
    )
}

export default CreateTicket