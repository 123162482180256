import React, { useEffect, useState } from 'react'
import _fetch from '../config/api';
import { base_url } from '../config/config'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'; // Add the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css';
const LeadershipBonus = () => {

    const [leader, setLeader] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [per_page] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [leftside, setLeftside] = useState('');
    const [rightside, setRight_side] = useState('');
    const [directbusines, setDirect_busines] = useState('');
    // const [usdtdirectbusiness, setUsdtdirectbusiness] = useState('');
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true);
            const request = await _fetch(`${base_url}api/leadership-bonus?page=${currentPage}&per_page=${per_page}`, 'GET', {}, {})
            console.log("level-income", request);
            if (request.status === true) {
                setLeader(request.response.rewards)
                setLeftside(request.response.left_side);
                setRight_side(request.response.right_side);
                setDirect_busines(request.response.direct_business);
                // setUsdtdirectbusiness(request.response.usdt_direct_business);
                setLoading(false);
            } else {
                toast.dismiss()
                toast.error(request.message)
                setLoading(false);
            }
            // setCurrentPage(parseInt(request.pagination.current_page, 10));
            // setTotalPages(parseInt(request.pagination.total_pages, 10));

        } catch (error) {
            console.log("Error fetching data:", error);
            setLoading(false);
        }
    }


    return (
        <>


            <div className="onew p-1">
                <div className="heading">
                    <h4 className='bep_2'>Leader Ship Bonus</h4>
                </div>
                <div class="card">
                    <div class="card-body cash">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <div className='ovbut '>
                                    <p className='ovi'> Left side:- {leftside}</p>
                                </div>
                                <div className='ovbut'>
                                    <p className='ovi'> Right side:- {rightside}</p>
                                    {/* Right side:- {rightside} */}
                                </div>

                            </div>
                            <div className="">

                                <div className='ovbut'  >
                                    <p className='ovi'>Directbusines:-  {directbusines}</p>
                                    {/* Cvt direct business:-  {cvtdirectbusiness} */}
                                </div>
                                {/* <div className='ovbut'>Usdt direct business:- {usdtdirectbusiness}</div> */}
                                <div className='ovbut'  >
                                    {/* <p className='ovi'>Usdt direct business:-  {usdtdirectbusiness}</p> */}
                                    {/* Cvt direct business:-  {cvtdirectbusiness} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded">

                    <div className="table-responsive mt-3">
                        {loading ? (
                            <Skeleton count={10} height={50} baseColor="#202020" highlightColor="#444" />
                        ) : (
                            <table class="table">
                                <thead className='table-heading'>
                                    <tr>
                                        <th scope="col">Sr.No.</th>
                                        <th scope="col">Matching</th>
                                        <th scope="col">Directs Business</th>
                                        <th scope="col">Rewards</th>
                                        <th scope="col">Bonus</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {/* <tbody className='tb_colo'>
                                {leader && leader.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                            <td scope='row'>{item.matching}</td>
                                            <td scope='row'>{item.direct}</td>
                                            <td scope='row'>{item.reward}</td>
                                            <td scope='row'>{item.bonus}</td>
                                            <td scope='row'>{item.action}</td>
                                        </tr>
                                    )
                                })}
                            </tbody> */}
                                <tbody className='tb_colo'>
                                    {leader && leader.length > 0 ? (
                                        leader.map((item, index) => {
                                            let buttonColor;
                                            let buttonText;
                                            let padding;
let color
                                            switch (item.action) {
                                                case 'Claimed':
                                                    buttonColor = 'rgb(65, 211, 62) ';
                                                    buttonText = 'Claimed';
                                                    color = '#000'
                                                    padding ='5px 10px'
                                                    break;
                                                case 'Claim':
                                                    buttonColor = 'rgb(65, 211, 62)';
                                                    buttonText = 'Claim';
                                                     padding = '5px 25px'
                                                
                                                    break;
                                                default:
                                                    buttonColor = 'none';
                                                    buttonText = 'Not Eligible';
                                                    break;
                                            }

                                            return (
                                                <tr key={index}>
                                                    <td scope='row'>{(currentPage - 1) * per_page + index + 1}</td>
                                                    <td scope='row'>{item.matching}</td>
                                                    <td scope='row'>{item.direct}</td>
                                                    <td scope='row'>{item.reward}</td>
                                                    <td scope='row'>{item.bonus}</td>
                                                    <td scope='row'>
                                                        {buttonText && (
                                                            <button
                                                                style={{
                                                                    backgroundColor: buttonColor,
                                                                    border: 'none',
                                                                    color: 'white',
                                                                    padding: padding,
                                                                    borderRadius: '5px'
                                                                
                                                                    
                                                                }}>
                                                                {buttonText}
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })) : (
                                        <tr>
                                            <td scope='row' colSpan={6} style={{ textAlign: 'center' }}>No data found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}

export default LeadershipBonus